import { Helmet } from 'react-helmet';

const Head = () => (
  <Helmet
    title="Document Imaging Company"
    meta={[
      {
        name: 'description',
        content:
          'Comience su camino a la transformación digital, digitalice sus archivos y convierta su archivo en expedientes digitales mediante las tecnologías de scanners y gestores documentales que mejorarán sus procesos.'
      }
    ]}
  >
    <html lang="es" />
    <title>Document Imaging Company</title>
    <script
      async
      src="https://www.googletagmanager.com/gtag/js?id=G-KKXY8HTMW3"
    ></script>
    <script src="gtag.js"></script>
  </Helmet>
);

export default Head;
