import React from 'react';
import { Link } from 'gatsby';
import LogoIcon from '../../svg/LogoIcon';

const Footer = () => (
  <footer className="py-16">
    <div className="w-64 mx-auto mb-6 lg:hidden">
      <LogoIcon />
    </div>
    <div className="container flex mx-auto">
      <div className="mx-auto text-center">
        <address>
          Frontera 101, Colonia Roma, Alcaldía Cuauhtemoc, CP 06700, CDMX
        </address>
        <p>01 (55) 5525 . 8882</p>
        <a href="mailto:informes@document.com.mx">informes@document.com.mx</a>
        <br />
        <Link to="/privacidad">Aviso de Privacidad</Link>
      </div>
    </div>
  </footer>
);

export default Footer;
