import React from 'react';

type ButtonProps = {
  size?: 'default' | 'lg' | 'xl';
  className?: string;
  type?: any;
};
const Button: React.FC<ButtonProps> = ({
  children,
  size,
  className,
  type = 'button'
}) => {
  const sizes = {
    default: `py-3 px-8`,
    lg: `py-4 px-12`,
    xl: `py-5 px-16 text-lg`
  };
  return (
    <button
      type={type}
      className={`
        ${sizes[size] || sizes.default}
        ${className}
        transition-colors
        duration-300
        bg-primary
        hover:bg-primary-darker
        rounded
        text-white
    `}
    >
      {children}
    </button>
  );
};

export default Button;
