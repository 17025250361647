import React from 'react';
import { Link } from 'gatsby';
import Head from '../Head';
// import AnchorLink from 'react-anchor-link-smooth-scroll';
import LogoIcon from '../../svg/LogoIcon';
import Button from '../Button';

const Header = () => (
  <>
    <Head />
    <header className="sticky top-0 z-50 bg-white shadow">
      <div className="container flex flex-col items-center justify-between px-8 py-4 mx-auto sm:flex-row">
        <div className="flex items-center text-2xl">
          <Link to="/">
            <div className="hidden w-full lg:block">
              <LogoIcon />
            </div>
          </Link>
        </div>
        <div className="flex mt-4 sm:mt-0">
          <Link className="px-4" to="/">
            Inicio
          </Link>
          <Link className="px-4" to="/#services">
            Servicios
          </Link>
          <Link className="px-4" to="/#productos">
            Productos
          </Link>
          <Link className="px-4" to="/contacto">
            Contacto
          </Link>
        </div>
        <div className="hidden md:block">
          <Link to="/contacto">
            <Button size="default" className="text-sm">
              Solicita tu Demo
            </Button>
          </Link>
        </div>
      </div>
    </header>
  </>
);

export default Header;
